@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.top-banner{
    position: relative;
    background-image: url(/static/media/home_banner_bd.751f37e6.svg);
    background-position: left;
    background-size: cover;
}

.third-block{
    position: relative;
    background-image: url(/static/media/home_banner_bd.751f37e6.svg);
    background-position: center;
    background-size: cover;
}
.btn-custom{
    padding: 0.4rem 1.5rem;
    border-radius: 0.2rem;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    display: inline-block;
    align-self: flex-start;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-lg-custom{
    padding: 0.8rem 1.59rem;
    font-size: 1rem;
}

.btn-info{
    background-color: var(--info-dark-light);
    color: var(--white);
 }

 .btn-info:hover{
    background-color: var(--info);
 }
 
 .btn-warning-custom{
    background-color: var(--warning);
    color: var(--black);
 }

 .btn-warning-custom:hover{
    background-color: var(--warning-dark);
 }

 .btn-light-custom{
    background-color: var(--white);
    color: var(--black);
 }

 .btn-light-custom:hover{
    background-color: var(--muted-light);
 }

 .btn-danger-custom{
    background-color: var(--danger);
    color: var(--white);
 }

 .btn-danger-custom:hover{
    background-color: var(--danger-dark);
 }

 .btn-success-custom{
    background-color: var(--success-dark);
    color: var(--white);
 }

 .btn-success-custom:hover{
    background-color: var(--success-dark);
 }


.header-wrapper{
    width: 100%;
    height: 3.5em;
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: var(--white);
}

.bd-navbar{
    background-color: #232f3e !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 10%);
}

.top-menu-holder{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.menu-item-nav{
    display: flex;
    float: left;
    cursor: pointer;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
}

.menu-item-nav span{
    font-weight: 500;
}

.menu-item-nav:hover{
    background-color: var(--info-x-light);
}

.menu-item-nav:hover > span{
    color: var(--info-dark);
}

.nav-icon-sm{
    display: none;
    float: right;
}

.bd-buttons>.btn, .bd-buttons>.btn-group {
    margin: .25rem .125rem;
}

.logo-top{
    width: 15rem;
}

.logo-top-sm{
    width: 12rem;
}

@media (min-width: 768px){
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}


.bd-navbar {
    min-height: 4rem;
    background-color: var(--info-dark);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 10%);
 }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
   margin: 0px;
   padding: 0px;
   border: 0px;
   font: inherit;
   vertical-align: baseline;
}

body {
   /* font-family: 'Poppins', sans-serif !important; */
   color: var(--black) !important;
   -webkit-font-smoothing: antialiased !important;
   overflow-y:scroll !important;
   letter-spacing: -.006em !important;
   /* font-size: 0.878em  !important; */
   min-height: 100% !important;
   line-height: 1.5rem;
    font-weight: 400;
}

html {
   position: relative;
   min-height: 100%;
 }

 .footer-custom {
   width: 100%;
   background-color: #f5f5f5;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
 }

 .hide-scrollbar {
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
 }

 .hide-scrollbar::-webkit-scrollbar {
   display: none;
 }
 
/* ::-webkit-scrollbar {
   display: none !important;
} */

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
   margin-top: .35rem !important;
   margin-bottom: .35rem !important;
   font-weight: 600 !important;
   line-height: 1.2 !important;
}

.page-wrapper {
   background-color: var(--white) !important;
}

.bg-white-custom{
   background-color: var(--white) !important;
}

.bg-black-custom{
   background-color: var(--black) !important;
}

.bg-dark-custom{
   background-color: var(--info-dark) !important;
} 

.bg-info-custom{
   background-color: var(--info-badge-bg) !important;
}

.bg-info-dark-custom{
   background-color: var(--info-dark) !important;
}

.bg-info-light-custom{
   background-color: var(--info-light) !important;
}

.bg-success-custom{
   background-color: var(--success-light) !important;
}

.bg-success-custom > span{
   color: var(--success-dark);
}

.bg-warning-custom{
   background-color: var(--warning) !important;
}

.bg-muted-custom{
   background-color: var(--muted-extra-light) !important;
}

.bg-muted-light-custom{
   background-color: var(--dashboard-menu) !important;
}

.bg-muted-dark-custom{
   background-color: var(--muted) !important;
   color: var(--white) !important;
}

.bg-indigo-custom{
   background-color: var(--indigo) !important;
   color: var(--white) !important;
}

.bg-light-custom {
   background: #dcdfe3;
}

.ms-custom-left{
   margin-left: 4.5rem;
}

.text-success-custom{
   color: var(--success-dark);
}

.text-white-custom{
   color: var(--white);
}

.text-badge-info-custom{
   color: var(--info-badge-text);
}

.text-danger-custom{
   color: var(--danger-dark);
}

.text-info-custom{
   color: var(--info) !important;
}

.text-warning-custom{
   color: var(--warning);
}

.text-muted-custom{
   color: var(--muted);
}

.text-blue-dark-custom{
   color: var(--info-dark);
}

.text-muted-light-custom{
   color: var(--muted-light);
}

.muted-light-dark-text{
   color: var(--muted-light-dark-text);
}

.bg-white-custom{
   background-color: var(--white) !important;
}

.card-image-custom-holder{
   width: 100%;
   height: 20rem;
   overflow: hidden;
   background-color: var(--white);
}

.card-image-custom-holder > img{
   width: 100%;
}

.card{
   overflow: hidden;
   border-radius: 0.0 !important;
   border: none !important;
}

.bd-lead {
   font-size: calc(1rem + .3vw);
   font-weight: 400;
}

.how-it-works:before {
   content: '';
   position: absolute;
   left: 4px;
   top: 10px;
   width: 8px;
   height: 8px;
   background: #7731FF;
   border-radius: 100px;
   z-index: 2;
}

a{
   text-decoration: none !important;
}

.form-input-holder{
   margin-bottom: 1rem;
}

.form-input-header{
   display: block;
   padding: 0.145rem 0rem;
   line-height: 1rem;
   margin-bottom: 0.1rem;
}

.form-select {
    color: var(--info-dark) !important;
    border: 0.14rem solid var(--info-dark) !important;
}

.form-control,.form-select{
   color: var(--black);
   /* border: 0.14rem solid var(--info-dark) !important; */
   padding: 0.675rem 0.75rem !important;
}

.input-without-outline{
   background-color: var(--white) !important;
   outline: none;
   border: none;
   padding: 1%;
}

.modal{
   padding: 0 !important;
}

.form-control{
   display: block;
   width: 100%;
   padding: .375rem .75rem;
   color: var(--info-dark);
   -webkit-appearance: none;
   appearance: none;
   border: none;
   outline: none;
   background-color: var(--white);
   height: auto;
   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   border-radius: 0.33rem;
   border: 0.15rem solid var(--muted-light);
}

::-webkit-input-placeholder { 
   color: var(--muted);
   opacity: 1; 
 }

:-ms-input-placeholder { 
   color: var(--muted);
   opacity: 1; 
 }

::placeholder { 
   color: var(--muted);
   opacity: 1; 
 }

 .form-button-holder{
   display: flex;
   width: 100%;
   margin-top: 1rem;
   margin-bottom: 1rem;
}

.header-title-custom {
   color: var(--info-dark);
   font-weight: 700;
   font-size: 2.1rem;
}

.img-full-width{
   width: 100%;
   height: 100%;
}

.border-bottom-light{
   border-bottom: 0.1em solid var(--muted-light);
}

.border-bottom-light-sm{
   border-bottom: 0.05em solid var(--muted-light);
}

.chart-box-md{
   width: 3rem;
   height: 3rem;
}

.fw-bold-custom {
   font-weight: 700!important;
}

.fw-medium-custom {
   font-weight: 500!important;
}

.selection-muted{
   background-color: var(--muted-light);
   padding: 0.6rem 1rem;
   border-radius: 5rem;
   cursor: pointer;
}

.cursor-pointer{
   cursor: pointer;
}

.selection-muted > span {
   color: var(--black);
}

.selection-selected{
   background-color: var(--info);
   padding: 0.6rem 1rem;
   border-radius: 5rem;
   cursor: pointer;
}

.selection-selected > span {
   color: var(--white);
}

.loading-block-sm{
   width: 4rem;
   height: 4rem;
   background-color: var(--white);
   border-radius: 0.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.loading-image-sm{
   width: 2rem;
   height: 2rem;
}

.profile-static-card{
   height: 18rem;
}

.sl-heading-text {
   max-width:'53%';
 }
 @media (max-width: 1024px) {
   .sl-heading-text {
     max-width:'90%';
   }
 }

 .sl-preview-cta:hover .sl-preview-cta-ripple{transition:all 1s cubic-bezier(0,0,.2,1);inset:-0.75em!important;opacity:0!important}.sl-preview-cta:hover .sl-preview-cta-ripple-shadow{opacity:0.125!important;}

 .style-client-1{
   position:relative;display:flex;align-items:center;justify-content:center;border: 1px solid rgba(63,95,172,0.35);box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px
 }

 .style-client-2{
   position:absolute;inset:0;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:rgba(40, 37, 54, 0.9);z-index:999999;font-family:Poppins, Arial, sans-serif;font-size:clamp(20px, 2.664vw, 28px);font-weight:500;line-height:normal;text-align:center;border-radius:10px;
 }

 .style-client-3{
   color:#fff;margin-bottom:clamp(20px, 1.776vw, 24px);text-shadow: 0px 1px 2px rgba(26, 19, 72, 0.40);
 }

 .style-client-4{
   background-color:#feab10;border:none;border-radius:8px;box-shadow:0px 0px 15px rgba(26, 19, 72, 0.45);color:#000000;display:inline-block;font-family:Poppins, Arial, sans-serif;font-size:clamp(16px, 1.599vw, 20px);font-weight:600;height:clamp(40px, 3.996vw, 50px);line-height:1.2;padding:0 clamp(15px, 1.776vw, 20px);text-overflow:ellipsis;-webkit-transform:translateZ(0);transform:translateZ(0);transition:background-Color 0.4s;white-space:nowrap;width:auto;z-index:999999;cursor:pointer
 }

 .style-client-5{
   position:absolute;border:1px solid #feab10;inset:0;border-radius:inherit;pointer-events:none
 }

 .style-client-6{
   box-shadow:#feab10 0px 0px 4px 4px;opacity:0;border-radius:inherit;position:absolute;inset:0
 }

 .style-client-6{
   position:relative;padding-bottom:calc(47.50% + 27px);width:100%;height:0;-webkit-transform:scale(1);transform:scale(1);overflow:hidden;
 }

 .style-client-7{
   width:100%;height:100%;z-index:99999;position:absolute;background:url('https://storylane-prod-uploads.s3.us-east-2.amazonaws.com/company/company_b357eff0-60d1-4cde-9345-cb04051a2919/project/project_6794b887-9030-4cbb-855e-b8ca2f0ada1e/page/1704232186723.png') no-repeat;background-size:100% 100%;border-radius:inherit;
 }

 .style-client-8{
   width:100%;height:100%;z-index:99999;position:absolute;background:url('https://storylane-prod-uploads.s3.us-east-2.amazonaws.com/company/company_b357eff0-60d1-4cde-9345-cb04051a2919/project/project_6794b887-9030-4cbb-855e-b8ca2f0ada1e/page/1704232186723.png') no-repeat;background-size:100% 100%;border-radius:inherit;
 }

 .style-client-9{
   display:none;position:absolute;top:0;left:0;width:100%;height:100%;border:none;
 }

.profile-image-upload-holder{
   width: 100px;
   height: 100px;
   border: 0.2rem solid var(--muted-light);
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}

.profile-image-upload-holder-image{
   max-width: 100%;
   height: auto;
}

.left-menu-nav-wrapper a{
   color: var(--muted);
}

.p-wrap{
   white-space: pre-line;
}

.border-dark{
   border: 0.1rem solid var(--black)
}

.job-type-radio-button-active{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--info);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.job-type-radio-button-inactive{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--muted-extra-light);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.job-type-radio-button-active > span{
   color: var(--white);
}

.job-type-radio-button-inactive > span{
   color: var(--black);
}

.currently-working-button-active{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--success-dark);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.currently-working-button-active:before{
    content: '\2713';
    display: inline-block;
    color: var(--white);
    padding: 0 6px 0 0;
}

.job-type-radio-button-active:before{
   content: '\2713';
   display: inline-block;
   color: var(--white);
   padding: 0 6px 0 0;
}

.currently-working-button-inactive{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--muted-extra-light);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.currently-working-button-active > span{
   color: var(--white);
}

.job-type-radio-button-inactive > span{
   color: var(--black);
}

.form-control:disabled, .form-control[readonly] {
   background-color: #e9ecef;
   opacity: 1;
}

.search-sm-block{
   width: 100%;
   height: 3rem;
   background-color: var(--white);
   border-radius: 0.2rem;
}

.search-btn-sm-holder{
   height: 2rem;
}

.firm-logo-sm-custom{
   width: 6rem;
   height: 3rem;
   border: 0.05rem solid var(--muted-extra-light);
   align-items: center;
   justify-content: center;
   display: flex;
   border-radius: 0.3rem;
}

.firm-logo-sm-custom > img{
   height: 3rem;
   object-fit: cover;
}

.search-auto-complete-block{
   padding: 0.5rem;
   background-color: var(--muted-light);
   position: absolute;
   border-radius: 0.2rem;
   z-index: 99;
   overflow-y: scroll;
   max-height: 18.5rem;
   top: 3.7rem;
   width: 32.5% !important
}

.btn {
   display: flex !important;
   justify-content: center!important;
   align-items: center!important;
   font-size: .923rem !important;
}

.search-location-auto-complete-block{
   padding: 0.5rem;
   background-color: var(--muted-light);
   position: absolute;
   border-radius: 0.2rem;
   z-index: 99;
   overflow-y: scroll;
   max-height: 18.5rem;
   top: 3.7rem;
   width: 25% !important
}

@media (min-width: 320px) and (max-width: 480px) {
   .search-auto-complete-block{
      top: 3.8rem;
      width: 90% !important
   }

   .search-location-auto-complete-block{
      top: 7.1rem;
      width: 90% !important
   }
}

@media (min-width: 540px) and (max-width: 768px) {
   .search-auto-complete-block{
      top: 4.2rem;
      width: 50% !important;
   }

   .search-location-auto-complete-block{
      top: 7.8rem;
      width: 50% !important
   }
}

@media (min-width: 901px) and (max-width: 1023px) {
   .search-auto-complete-block{
      top: 4.2rem;
      width: 60% !important
   }

   .search-location-auto-complete-block{
      top: 7.8rem;
      width: 60% !important
   }
}

@media (min-width: 1024px) and (max-width: 1300px) {
   .search-auto-complete-block{
      top: 4.2rem;
      width: 32% !important
   }

   .search-location-auto-complete-block{
      top: 4.2rem;
      width: 32% !important
   }
}

@media (min-width: 769px) and (max-width: 900px) {
   .search-auto-complete-block{
      top: 4.1rem;
      width: 50% !important
   }

   .search-location-auto-complete-block{
      top: 7.8rem;
      width: 50% !important
   }
}

.search-auto-complete-block::-webkit-scrollbar {
   display: none;
 }

 .search-location-auto-complete-block::-webkit-scrollbar {
   display: none;
 }

.offcanvas-start{
   width: 18rem !important;
   background-color: var(--white) !important;
}

.btn{
   border-radius: 0.2rem !important;
}

.bg-danger-logo-custom{
   background-color: var(--logo-color-danger) !important;
}

.bg-warning-logo-custom{
   background-color: var(--logo-color-warning) !important;
}

.bg-blue-logo-custom{
   background-color: var(--logo-color-blue) !important;
}

.header-text-title-custom{
   font-size: 1.2rem;
   font-weight: 700;
}

.alert{
   margin-bottom: 0 !important;
}

.desc{
   line-height: 1.4;
   font-weight: 300 !important;
   color: rgb(54, 54, 54);
   font-size: 1.2rem !important;
}

.btn-group-sm>.btn, .btn-sm {
   padding: 0.15rem 0.5rem 0.1rem 0.5rem !important;
}

.btn-success {
   background-color: #39cb7f !important;
   border-color: #39cb7f !important;
}

.btn-secondary {
   background-color: #6c757d !important;
   border-color: #6c757d !important;
}

span {
   white-space: pre-wrap !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
   color: var(--white) !important;
   background-color: var(--info-dark-light) !important;
   border: 0 !important;
}

.nav-tabs .nav-link {
   border-top-left-radius: 0 !important;
   border-top-right-radius: 0 !important;
   margin-right: 0.3rem;
   background-color: var(--muted-extra-light) !important;
   color: var(--black);
   border-radius: 0.3rem !important;
}

.nav-tabs{
   border-bottom: 0 !important;
}

.nav-link{
   padding: 0.5rem 1rem 0.2rem 1rem !important;
}

.rounded-lg{
   border-radius: 1.25rem !important;
}

.raw-skill-holder{
   overflow-y: scroll;
   max-height: 25.5rem;
}

.modal-fullscreen{
   width: 99vw !important;
}

.btn-light {
   background-color: #eee !important;
}
:root {
    --info: rgb(0, 112, 201);
    --info-dark-light: #105da5;
    --info-icon-text: #24bae1;
    --info-dark: #334680;
    --info-light: #e4f9fd;
    --info-x-light: #e7f6fc;
    --black: #000839;
    --danger: #fd3b32;
    --danger-dark: #f91c4d;
    --danger-light: #ffdbd9;
    --success-light: #a8ffd6;
    --success: #27bd77;
    --success-dark: #21b872;
    --muted: #777e89;
    --muted-light-dark-text: #B5B5C3;
    --muted-light: #d2dae6;
    --warning: #ffa515;
    --warning-dark: #ffb60d;
    --warning-light: #fff2b1;
    --white: #FFFFFF;
    --white-x-dark: #b2bfcc;
    --white-dark: #fafcfe;
    --muted-extra-light: #eaeaec;
    --dashboard-menu: #edf2f9;
    --indigo: #563d7c;
    --info-badge-bg: #e4f7fd;
    --info-badge-text: #36b5f3;
    --bg: rgb(0, 69, 91);
    --logo-color-danger: rgb(252,51,56,255);
    --logo-color-warning: rgb(255,173,17,255);
    --logo-color-blue: rgb(20,105,170,255);
    --background-color: #fafbfc;
    --top-header-bg: rgb(46, 63, 80);
}
.static-page-header{
    position: relative;
    background-image: url(/static/media/home_banner_bd.751f37e6.svg);
    background-position: center;
    background-size: cover;
}
.main-layout{
    position: relative;
}

.sidebar-main {
    width: 100%;
    /* margin-top: 20px; */
}

.sm-block{
    width: 2.2rem;
    height: 2.2rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 0.2rem;
    overflow: hidden;
}

.sm-block-rounded{
   width: 2.2rem;
   height: 2.2rem;
   justify-content: center;
   align-items: center;
   display: flex;
   border-radius: 2.2rem;
   overflow: hidden;
}

.animated-hover{
    cursor: pointer;
    transition: 0.3s;
}

.animated-hover:hover{
    background-color: var(--muted-extra-light) !important;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color: var(--black) !important;
}

.animated-hover:hover > div {
    color: var(--muted) !important;
}

.profile-image-sm-rounded{
   width: 2.2rem;
   height:2.2rem;
   box-sizing: border-box;
   background-color: var(--white);
   overflow: hidden;
   border-radius: 1.1rem;
   cursor: pointer;
}

.profile-image-sm{
    width: 2.2rem;
    height:2.2rem;
    box-sizing: border-box;
    background-color: var(--muted-extra-light);
    overflow: hidden;
    border-radius: 0.4rem;
    cursor: pointer;
 }

 .profile-image-sm > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

 .profile-image-md{
   width: 2.8rem;
   height:2.8rem;
   box-sizing: border-box;
   background-color: var(--muted-extra-light);
   overflow: hidden;
   border-radius: 0.4rem;
   cursor: pointer;
}

.profile-image-md > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.profile-image-lg{
   width: 6rem;
   height: 6rem;
   box-sizing: border-box;
   background-color: var(--muted-extra-light);
   overflow: hidden;
   border-radius: 0.4rem;
   cursor: pointer;
}

.profile-image-lg > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.profile-image-lg-rounder{
   width: 6rem;
   height: 6rem;
   box-sizing: border-box;
   background-color: var(--muted-extra-light);
   overflow: hidden;
   border-radius: 3rem;
   cursor: pointer;
}

.profile-image-lg-rounder > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

 .profile-details-holder{
    width: 20rem;
 }

 .card-custom{
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: var(--white);
      background-clip: border-box;
      /* -webkit-box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%);
      box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%); */
      border-radius: 0.5rem; 
      box-shadow:0 2px 5px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      overflow: hidden;
      /* border: 1px solid #6c757d!important; */
   }

   .shadow-sm-custom{
      --T68779821: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
   }
 .card-title-custom{
    color: var(--info-dark);
    font-size: 0.975rem;
    line-height: 1.5;
    font-weight: 700;
 }

 .icon-holder-lg{
   width: 3rem;
   height: 3rem;
   border-radius: 3rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .icon-holder-sm{
   width: 2rem;
   height: 2rem;
   border-radius: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .icon-holder-md{
   padding-left: 0.8rem;
   padding-right: 0.8rem;
   height: 2.6rem;
   border-radius: 2.6rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .icon-holder-white{
   background-color: var(--white);
   color: var(--black) !important;
 }

 .icon-holder-info{
   background-color: var(--info-light);
   color: var(--info) !important;
 }

 .icon-holder-warning{
   background-color: var(--warning-light);
   color: var(--warning) !important;
 }

 .icon-holder-success{
   background-color: var(--success-light);
   color: var(--success-dark) !important;
 }

 .icon-holder-danger{
    background-color: var(--danger-light);
    color: var(--danger) !important;
 }

 .icon-holder-dark{
    background-color: var(--muted-light);
    color: var(--black) !important;
 }

 .icon-holder-ingigo{
    background-color: var(--indigo);
    color: var(--white) !important;
 }

 .block-custom{
    display: block;
 }

 .card-body-sm{
    padding: 0.6rem;
 }

 .cursor-pointer{
    cursor: pointer;
 }

 .card-header-custom{
      flex: 1 1 auto;
      padding: 1rem 1rem;
 }

 .firm-logo-sm{
   width: 10rem;
   height: 6rem;
   box-sizing: border-box;
   overflow: hidden;
   border-radius: 0.2rem;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .firm-logo-sm > img{
   width: auto;
   height:70%;
   object-fit: contain;
 }
.alert-custom {
    display: block;
    padding: 0.2em 1em;
    border-radius: 0.2rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.4rem;
}

.alert-danger-custom {
    background-color: var(--danger-light);
    border: 0.05rem solid var(--danger-dark);
}

.alert-success-custom {
    background-color: var(--success);
    border: 0.05rem solid var(--success-dark);
}

.alert-info-custom {
    background-color: var(--info-light);
    border: 0.05rem solid var(--info);
}

.alert-warning-custom {
    background-color: var(--warning-light);
    border: 0.05rem solid var(--warning);
}
.badge-custom{
    border-radius: 1rem;
    padding: 0.16rem 1.3rem;
}

.badge-success{
    background-color: var(--success-dark);
}

.badge-success > span {
    color: var(--white);
}
#search-box {
    height: 100%;
    margin-right: 8px;
}

.search-setting {
    margin-right: 8px;
}

.search-btn {
    height: 100%;
}
.icon-sm{
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-img-lg-holder{
    display: flex;
    height: 7rem;
    justify-content: center;
    align-items: center;
}

.profile-img-lg{
    width: 6rem;
    height: 6rem;
    background-color: var(--muted-light);
    border-radius: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-name-lg-holder{
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-favorite-holder{
    height: 2.9rem;
    width: 2.9rem;
    display: flex;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    justify-content: center;
    align-items: center;
    /* background: var(--muted-extra-light); */
    z-index: 2;
    border-radius: 0.3rem;
    cursor: pointer;
}

.profile-favorite-holder-left{
    height: 1.7rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-radius: 0.3rem;
}

.role-lg-holder{
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.address-lg-holder{
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-bio-lg-holder{
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-info-block-holder{
    display: block
}

.general-info-block{
    display: flex;
    height: 1.5rem;
}

.info-icon-holder{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.profile-bio-holder-card{
    width: 100%;
    height: 13rem;
    overflow-y: auto;
    background-color: var(--muted-light);
    border-radius: 0.5rem;
    padding: 0.8rem;
    margin-bottom: 1rem;
}

.document-box-sm{
    background-color: var(--white);
    border: 0.02rem solid var(--black);
    border-radius: 0.3rem;
}

.skill-holder{
    height: 13.8rem;
    overflow-y:hidden;
    display: flex;
}

.skill-wrapper{
    padding: 0.15rem 1.2rem;
    background-color: var(--success-dark);
    align-self: flex-start;
    border-radius: 1rem;
    width: auto;
}

.skill-wrapper > span{
    color: var(--white);
}

.skill-wrapper-muted{
    padding: 0.15rem 1.2rem;
    background-color: var(--muted-extra-light);
    align-self: flex-start;
    border-radius: 0.2rem;
    width: auto;
}

.skill-wrapper-muted > span{
    color: var(--black);
}

.skill-wrapper-success{
    padding: 0.15rem 1.2rem;
    background-color: var(--success-light);
    align-self: flex-start;
    border-radius: 0.2rem;
    width: auto;
}

.skill-wrapper-success > span{
    color: var(--black);
}

.skill-wrapper-dark{
    padding: 0.1rem 1rem 0.1rem 1rem;
    background-color: var(--top-header-bg);
    align-self: flex-start;
    border-radius: 0.2rem;
    width: auto;
}

.skill-wrapper-dark > span{
    color: var(--white);
}

.profile-tab-container{
    background-color: var(--white);
    border-radius: 0.2rem;
    display: flex;
    overflow-x:auto;
}

.profile-tab{
    background-color: var(--background-color);
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.profile-tab-selected{
    background-color: var(--info);
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.profile-tab-selected > span {
    color: var(--white);
}

.top-card-section{
    height: 21.2rem;
}

.docuament-box-holder{
    height: 20rem;
}

.document-holder-inner{
    /* height: 12.8rem; */
    overflow-y: hidden;
}

.firm-logo-lg{
    width: 10rem;
    height: 5rem;
}

.firm-logo-lg img{
    width: 10rem;
    height: auto !important;
}

.profile-tab-section{
    min-height: 20rem;
}
.auth-wrapper{
    display: flex;
    background-color: var(--info-x-light);
}

.login-page-lg-image{
    width: 80%;
    height: 20rem;
}
html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
}

.orgchart-container {
  border: none !important;
}
.sekure-org-chart {
  background-image: none !important;
}
.orgchart > ul > li > ul li::before {
  border-top: 2px solid #6c757d !important;
}

.orgchart > ul > li > ul li > .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
  height: 14px !important;
  background-color: #6c757d !important;
}

.orgchart ul li .oc-node:hover,
.orgchart ul li .oc-node.selected {
  background-color: transparent !important;
}

.oc-edge.leftEdge,
.oc-edge.rightEdge,
.oc-edge.topEdge,
.oc-edge.bottomEdge {
  display: none !important;
}

.org-node-container {
  border: 2px solid #6c757d;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 60px;
  min-width: 50px;
}

.org-person {
  color: #27b3cc;
  justify-content: center;
  display: flex;
}
.org-person > div {
  border-radius: 30px;
  border: 2px solid #27b3cc;
  padding: 5px;
}
.org-name {
  font-size: 16px;
}
.org-node-children {
  color: #27b3cc;
  text-decoration: underline;
  cursor: pointer;
}

.open-user-details {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #27b3cc;
  cursor: pointer;
}

.card12 button {
  border: 0;
  outline: 0;
  color: #ffff;
  border-radius: 4px;
  padding: 8px 13px;
  font-weight: 400;
  width: 100%;
}
.card12 .drag-area12 {
  border: 2px dashed #0d6efd;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
  overflow-y: auto;
}
.card12 .select12 {
  color: #ffff;
  margin-left: 5px;
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 10px;
  transition: 0.4s;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.card12 .select12:hover {
  opacity: 0.6s;
}
.card12 .filee-container12 {
  width: 100%;
  height: auto;
  grid-gap: 5px;
  gap: 5px;
  max-height: 200px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.card12 input {
  display: none;
}

.delete12 {
  z-index: 9999;
  color: #0d6efd;
  cursor: pointer;
}
.card12 .file-button12 button {
  margin-top: 10px;
  text-transform: uppercase;
  background: #0d6efd;
}
.card12 .file-disable12 button {
  margin-top: 10px;
  text-transform: uppercase;
  background: #8ebafb;
}
.ul-file-list12 li {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  color: black;
  font-weight: bold;
  margin-left: 20px;
}
.errmsg12 {
  color: red;
}
.drop-file12 {
  font-size: 16px;
  color: #868e96;
}
.file-icon12 {
  font-size: 28px;
  color: #929090;
}

#search-box {
    height: 100%;
    margin-right: 8px;
}

.search-setting {
    margin-right: 8px;
}

.search-btn {
    height: 100%;
}
