@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
   margin: 0px;
   padding: 0px;
   border: 0px;
   font: inherit;
   vertical-align: baseline;
}

body {
   /* font-family: 'Poppins', sans-serif !important; */
   color: var(--black) !important;
   -webkit-font-smoothing: antialiased !important;
   overflow-y:scroll !important;
   letter-spacing: -.006em !important;
   /* font-size: 0.878em  !important; */
   min-height: 100% !important;
   line-height: 1.5rem;
    font-weight: 400;
}

html {
   position: relative;
   min-height: 100%;
 }

 .footer-custom {
   width: 100%;
   background-color: #f5f5f5;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
 }

 .hide-scrollbar {
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
 }

 .hide-scrollbar::-webkit-scrollbar {
   display: none;
 }
 
/* ::-webkit-scrollbar {
   display: none !important;
} */

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
   margin-top: .35rem !important;
   margin-bottom: .35rem !important;
   font-weight: 600 !important;
   line-height: 1.2 !important;
}

.page-wrapper {
   background-color: var(--white) !important;
}

.bg-white-custom{
   background-color: var(--white) !important;
}

.bg-black-custom{
   background-color: var(--black) !important;
}

.bg-dark-custom{
   background-color: var(--info-dark) !important;
} 

.bg-info-custom{
   background-color: var(--info-badge-bg) !important;
}

.bg-info-dark-custom{
   background-color: var(--info-dark) !important;
}

.bg-info-light-custom{
   background-color: var(--info-light) !important;
}

.bg-success-custom{
   background-color: var(--success-light) !important;
}

.bg-success-custom > span{
   color: var(--success-dark);
}

.bg-warning-custom{
   background-color: var(--warning) !important;
}

.bg-muted-custom{
   background-color: var(--muted-extra-light) !important;
}

.bg-muted-light-custom{
   background-color: var(--dashboard-menu) !important;
}

.bg-muted-dark-custom{
   background-color: var(--muted) !important;
   color: var(--white) !important;
}

.bg-indigo-custom{
   background-color: var(--indigo) !important;
   color: var(--white) !important;
}

.bg-light-custom {
   background: #dcdfe3;
}

.ms-custom-left{
   margin-left: 4.5rem;
}

.text-success-custom{
   color: var(--success-dark);
}

.text-white-custom{
   color: var(--white);
}

.text-badge-info-custom{
   color: var(--info-badge-text);
}

.text-danger-custom{
   color: var(--danger-dark);
}

.text-info-custom{
   color: var(--info) !important;
}

.text-warning-custom{
   color: var(--warning);
}

.text-muted-custom{
   color: var(--muted);
}

.text-blue-dark-custom{
   color: var(--info-dark);
}

.text-muted-light-custom{
   color: var(--muted-light);
}

.muted-light-dark-text{
   color: var(--muted-light-dark-text);
}

.bg-white-custom{
   background-color: var(--white) !important;
}

.card-image-custom-holder{
   width: 100%;
   height: 20rem;
   overflow: hidden;
   background-color: var(--white);
}

.card-image-custom-holder > img{
   width: 100%;
}

.card{
   overflow: hidden;
   border-radius: 0.0 !important;
   border: none !important;
}

.bd-lead {
   font-size: calc(1rem + .3vw);
   font-weight: 400;
}

.how-it-works:before {
   content: '';
   position: absolute;
   left: 4px;
   top: 10px;
   width: 8px;
   height: 8px;
   background: #7731FF;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
   border-radius: 100px;
   z-index: 2;
}

a{
   text-decoration: none !important;
}

.form-input-holder{
   margin-bottom: 1rem;
}

.form-input-header{
   display: block;
   padding: 0.145rem 0rem;
   line-height: 1rem;
   margin-bottom: 0.1rem;
}

.form-select {
    color: var(--info-dark) !important;
    border: 0.14rem solid var(--info-dark) !important;
}

.form-control,.form-select{
   color: var(--black);
   /* border: 0.14rem solid var(--info-dark) !important; */
   padding: 0.675rem 0.75rem !important;
}

.input-without-outline{
   background-color: var(--white) !important;
   outline: none;
   border: none;
   padding: 1%;
}

.modal{
   padding: 0 !important;
}

.form-control{
   display: block;
   width: 100%;
   padding: .375rem .75rem;
   color: var(--info-dark);
   -webkit-appearance: none;
   appearance: none;
   border: none;
   outline: none;
   background-color: var(--white);
   height: auto;
   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   border-radius: 0.33rem;
   border: 0.15rem solid var(--muted-light);
}

::placeholder { 
   color: var(--muted);
   opacity: 1; 
 }

 .form-button-holder{
   display: flex;
   width: 100%;
   margin-top: 1rem;
   margin-bottom: 1rem;
}

.header-title-custom {
   color: var(--info-dark);
   font-weight: 700;
   font-size: 2.1rem;
}

.img-full-width{
   width: 100%;
   height: 100%;
}

.border-bottom-light{
   border-bottom: 0.1em solid var(--muted-light);
}

.border-bottom-light-sm{
   border-bottom: 0.05em solid var(--muted-light);
}

.chart-box-md{
   width: 3rem;
   height: 3rem;
}

.fw-bold-custom {
   font-weight: 700!important;
}

.fw-medium-custom {
   font-weight: 500!important;
}

.selection-muted{
   background-color: var(--muted-light);
   padding: 0.6rem 1rem;
   border-radius: 5rem;
   cursor: pointer;
}

.cursor-pointer{
   cursor: pointer;
}

.selection-muted > span {
   color: var(--black);
}

.selection-selected{
   background-color: var(--info);
   padding: 0.6rem 1rem;
   border-radius: 5rem;
   cursor: pointer;
}

.selection-selected > span {
   color: var(--white);
}

.loading-block-sm{
   width: 4rem;
   height: 4rem;
   background-color: var(--white);
   border-radius: 0.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.loading-image-sm{
   width: 2rem;
   height: 2rem;
}

.profile-static-card{
   height: 18rem;
}

.sl-heading-text {
   max-width:'53%';
 }
 @media (max-width: 1024px) {
   .sl-heading-text {
     max-width:'90%';
   }
 }

 .sl-preview-cta:hover .sl-preview-cta-ripple{transition:all 1s cubic-bezier(0,0,.2,1);inset:-0.75em!important;opacity:0!important}.sl-preview-cta:hover .sl-preview-cta-ripple-shadow{opacity:0.125!important;}

 .style-client-1{
   position:relative;display:flex;align-items:center;justify-content:center;border: 1px solid rgba(63,95,172,0.35);box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px
 }

 .style-client-2{
   position:absolute;inset:0;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:rgba(40, 37, 54, 0.9);z-index:999999;font-family:Poppins, Arial, sans-serif;font-size:clamp(20px, 2.664vw, 28px);font-weight:500;line-height:normal;text-align:center;border-radius:10px;
 }

 .style-client-3{
   color:#fff;margin-bottom:clamp(20px, 1.776vw, 24px);text-shadow: 0px 1px 2px rgba(26, 19, 72, 0.40);
 }

 .style-client-4{
   background-color:#feab10;border:none;border-radius:8px;box-shadow:0px 0px 15px rgba(26, 19, 72, 0.45);color:#000000;display:inline-block;font-family:Poppins, Arial, sans-serif;font-size:clamp(16px, 1.599vw, 20px);font-weight:600;height:clamp(40px, 3.996vw, 50px);line-height:1.2;padding:0 clamp(15px, 1.776vw, 20px);text-overflow:ellipsis;transform:translateZ(0);transition:background-Color 0.4s;white-space:nowrap;width:auto;z-index:999999;cursor:pointer
 }

 .style-client-5{
   position:absolute;border:1px solid #feab10;inset:0;border-radius:inherit;pointer-events:none
 }

 .style-client-6{
   box-shadow:#feab10 0px 0px 4px 4px;opacity:0;border-radius:inherit;position:absolute;inset:0
 }

 .style-client-6{
   position:relative;padding-bottom:calc(47.50% + 27px);width:100%;height:0;transform:scale(1);overflow:hidden;
 }

 .style-client-7{
   width:100%;height:100%;z-index:99999;position:absolute;background:url('https://storylane-prod-uploads.s3.us-east-2.amazonaws.com/company/company_b357eff0-60d1-4cde-9345-cb04051a2919/project/project_6794b887-9030-4cbb-855e-b8ca2f0ada1e/page/1704232186723.png') no-repeat;background-size:100% 100%;border-radius:inherit;
 }

 .style-client-8{
   width:100%;height:100%;z-index:99999;position:absolute;background:url('https://storylane-prod-uploads.s3.us-east-2.amazonaws.com/company/company_b357eff0-60d1-4cde-9345-cb04051a2919/project/project_6794b887-9030-4cbb-855e-b8ca2f0ada1e/page/1704232186723.png') no-repeat;background-size:100% 100%;border-radius:inherit;
 }

 .style-client-9{
   display:none;position:absolute;top:0;left:0;width:100%;height:100%;border:none;
 }

.profile-image-upload-holder{
   width: 100px;
   height: 100px;
   border: 0.2rem solid var(--muted-light);
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}

.profile-image-upload-holder-image{
   max-width: 100%;
   height: auto;
}

.left-menu-nav-wrapper a{
   color: var(--muted);
}

.p-wrap{
   white-space: pre-line;
}

.border-dark{
   border: 0.1rem solid var(--black)
}

.job-type-radio-button-active{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--info);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.job-type-radio-button-inactive{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--muted-extra-light);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.job-type-radio-button-active > span{
   color: var(--white);
}

.job-type-radio-button-inactive > span{
   color: var(--black);
}

.currently-working-button-active{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--success-dark);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.currently-working-button-active:before{
    content: '\2713';
    display: inline-block;
    color: var(--white);
    padding: 0 6px 0 0;
}

.job-type-radio-button-active:before{
   content: '\2713';
   display: inline-block;
   color: var(--white);
   padding: 0 6px 0 0;
}

.currently-working-button-inactive{
   height: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: flex-start;
   background-color: var(--muted-extra-light);
   cursor: pointer;
   padding-left: 1rem;
   padding-right: 1rem;
   border-radius: 0.2rem;
}

.currently-working-button-active > span{
   color: var(--white);
}

.job-type-radio-button-inactive > span{
   color: var(--black);
}

.form-control:disabled, .form-control[readonly] {
   background-color: #e9ecef;
   opacity: 1;
}

.search-sm-block{
   width: 100%;
   height: 3rem;
   background-color: var(--white);
   border-radius: 0.2rem;
}

.search-btn-sm-holder{
   height: 2rem;
}

.firm-logo-sm-custom{
   width: 6rem;
   height: 3rem;
   border: 0.05rem solid var(--muted-extra-light);
   align-items: center;
   justify-content: center;
   display: flex;
   border-radius: 0.3rem;
}

.firm-logo-sm-custom > img{
   height: 3rem;
   object-fit: cover;
}

.search-auto-complete-block{
   padding: 0.5rem;
   background-color: var(--muted-light);
   position: absolute;
   border-radius: 0.2rem;
   z-index: 99;
   overflow-y: scroll;
   max-height: 18.5rem;
   top: 3.7rem;
   width: 32.5% !important
}

.btn {
   display: flex !important;
   justify-content: center!important;
   align-items: center!important;
   font-size: .923rem !important;
}

.search-location-auto-complete-block{
   padding: 0.5rem;
   background-color: var(--muted-light);
   position: absolute;
   border-radius: 0.2rem;
   z-index: 99;
   overflow-y: scroll;
   max-height: 18.5rem;
   top: 3.7rem;
   width: 25% !important
}

@media (min-width: 320px) and (max-width: 480px) {
   .search-auto-complete-block{
      top: 3.8rem;
      width: 90% !important
   }

   .search-location-auto-complete-block{
      top: 7.1rem;
      width: 90% !important
   }
}

@media (min-width: 540px) and (max-width: 768px) {
   .search-auto-complete-block{
      top: 4.2rem;
      width: 50% !important;
   }

   .search-location-auto-complete-block{
      top: 7.8rem;
      width: 50% !important
   }
}

@media (min-width: 901px) and (max-width: 1023px) {
   .search-auto-complete-block{
      top: 4.2rem;
      width: 60% !important
   }

   .search-location-auto-complete-block{
      top: 7.8rem;
      width: 60% !important
   }
}

@media (min-width: 1024px) and (max-width: 1300px) {
   .search-auto-complete-block{
      top: 4.2rem;
      width: 32% !important
   }

   .search-location-auto-complete-block{
      top: 4.2rem;
      width: 32% !important
   }
}

@media (min-width: 769px) and (max-width: 900px) {
   .search-auto-complete-block{
      top: 4.1rem;
      width: 50% !important
   }

   .search-location-auto-complete-block{
      top: 7.8rem;
      width: 50% !important
   }
}

.search-auto-complete-block::-webkit-scrollbar {
   display: none;
 }

 .search-location-auto-complete-block::-webkit-scrollbar {
   display: none;
 }

.offcanvas-start{
   width: 18rem !important;
   background-color: var(--white) !important;
}

.btn{
   border-radius: 0.2rem !important;
}

.bg-danger-logo-custom{
   background-color: var(--logo-color-danger) !important;
}

.bg-warning-logo-custom{
   background-color: var(--logo-color-warning) !important;
}

.bg-blue-logo-custom{
   background-color: var(--logo-color-blue) !important;
}

.header-text-title-custom{
   font-size: 1.2rem;
   font-weight: 700;
}

.alert{
   margin-bottom: 0 !important;
}

.desc{
   line-height: 1.4;
   font-weight: 300 !important;
   color: rgb(54, 54, 54);
   font-size: 1.2rem !important;
}

.btn-group-sm>.btn, .btn-sm {
   padding: 0.15rem 0.5rem 0.1rem 0.5rem !important;
}

.btn-success {
   background-color: #39cb7f !important;
   border-color: #39cb7f !important;
}

.btn-secondary {
   background-color: #6c757d !important;
   border-color: #6c757d !important;
}

span {
   white-space: pre-wrap !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
   color: var(--white) !important;
   background-color: var(--info-dark-light) !important;
   border: 0 !important;
}

.nav-tabs .nav-link {
   border-top-left-radius: 0 !important;
   border-top-right-radius: 0 !important;
   margin-right: 0.3rem;
   background-color: var(--muted-extra-light) !important;
   color: var(--black);
   border-radius: 0.3rem !important;
}

.nav-tabs{
   border-bottom: 0 !important;
}

.nav-link{
   padding: 0.5rem 1rem 0.2rem 1rem !important;
}

.rounded-lg{
   border-radius: 1.25rem !important;
}

.raw-skill-holder{
   overflow-y: scroll;
   max-height: 25.5rem;
}

.modal-fullscreen{
   width: 99vw !important;
}

.btn-light {
   background-color: #eee !important;
}