.card12 button {
  border: 0;
  outline: 0;
  color: #ffff;
  border-radius: 4px;
  padding: 8px 13px;
  font-weight: 400;
  width: 100%;
}
.card12 .drag-area12 {
  border: 2px dashed #0d6efd;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
  overflow-y: auto;
}
.card12 .select12 {
  color: #ffff;
  margin-left: 5px;
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 10px;
  transition: 0.4s;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.card12 .select12:hover {
  opacity: 0.6s;
}
.card12 .filee-container12 {
  width: 100%;
  height: auto;
  gap: 5px;
  max-height: 200px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.card12 input {
  display: none;
}

.delete12 {
  z-index: 9999;
  color: #0d6efd;
  cursor: pointer;
}
.card12 .file-button12 button {
  margin-top: 10px;
  text-transform: uppercase;
  background: #0d6efd;
}
.card12 .file-disable12 button {
  margin-top: 10px;
  text-transform: uppercase;
  background: #8ebafb;
}
.ul-file-list12 li {
  display: flex;
  gap: 4px;
  color: black;
  font-weight: bold;
  margin-left: 20px;
}
.errmsg12 {
  color: red;
}
.drop-file12 {
  font-size: 16px;
  color: #868e96;
}
.file-icon12 {
  font-size: 28px;
  color: #929090;
}
