:root {
    --info: rgb(0, 112, 201);
    --info-dark-light: #105da5;
    --info-icon-text: #24bae1;
    --info-dark: #334680;
    --info-light: #e4f9fd;
    --info-x-light: #e7f6fc;
    --black: #000839;
    --danger: #fd3b32;
    --danger-dark: #f91c4d;
    --danger-light: #ffdbd9;
    --success-light: #a8ffd6;
    --success: #27bd77;
    --success-dark: #21b872;
    --muted: #777e89;
    --muted-light-dark-text: #B5B5C3;
    --muted-light: #d2dae6;
    --warning: #ffa515;
    --warning-dark: #ffb60d;
    --warning-light: #fff2b1;
    --white: #FFFFFF;
    --white-x-dark: #b2bfcc;
    --white-dark: #fafcfe;
    --muted-extra-light: #eaeaec;
    --dashboard-menu: #edf2f9;
    --indigo: #563d7c;
    --info-badge-bg: #e4f7fd;
    --info-badge-text: #36b5f3;
    --bg: rgb(0, 69, 91);
    --logo-color-danger: rgb(252,51,56,255);
    --logo-color-warning: rgb(255,173,17,255);
    --logo-color-blue: rgb(20,105,170,255);
    --background-color: #fafbfc;
    --top-header-bg: rgb(46, 63, 80);
}