@media (min-width: 768px){
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}


.bd-navbar {
    min-height: 4rem;
    background-color: var(--info-dark);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 10%);
 }
