.main-layout{
    position: relative;
}

.sidebar-main {
    width: 100%;
    /* margin-top: 20px; */
}

.sm-block{
    width: 2.2rem;
    height: 2.2rem;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 0.2rem;
    overflow: hidden;
}

.sm-block-rounded{
   width: 2.2rem;
   height: 2.2rem;
   justify-content: center;
   align-items: center;
   display: flex;
   border-radius: 2.2rem;
   overflow: hidden;
}

.animated-hover{
    cursor: pointer;
    transition: 0.3s;
}

.animated-hover:hover{
    background-color: var(--muted-extra-light) !important;
    transform: scale(1.1);
    color: var(--black) !important;
}

.animated-hover:hover > div {
    color: var(--muted) !important;
}

.profile-image-sm-rounded{
   width: 2.2rem;
   height:2.2rem;
   box-sizing: border-box;
   background-color: var(--white);
   overflow: hidden;
   border-radius: 1.1rem;
   cursor: pointer;
}

.profile-image-sm{
    width: 2.2rem;
    height:2.2rem;
    box-sizing: border-box;
    background-color: var(--muted-extra-light);
    overflow: hidden;
    border-radius: 0.4rem;
    cursor: pointer;
 }

 .profile-image-sm > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

 .profile-image-md{
   width: 2.8rem;
   height:2.8rem;
   box-sizing: border-box;
   background-color: var(--muted-extra-light);
   overflow: hidden;
   border-radius: 0.4rem;
   cursor: pointer;
}

.profile-image-md > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.profile-image-lg{
   width: 6rem;
   height: 6rem;
   box-sizing: border-box;
   background-color: var(--muted-extra-light);
   overflow: hidden;
   border-radius: 0.4rem;
   cursor: pointer;
}

.profile-image-lg > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.profile-image-lg-rounder{
   width: 6rem;
   height: 6rem;
   box-sizing: border-box;
   background-color: var(--muted-extra-light);
   overflow: hidden;
   border-radius: 3rem;
   cursor: pointer;
}

.profile-image-lg-rounder > img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}

 .profile-details-holder{
    width: 20rem;
 }

 .card-custom{
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: var(--white);
      background-clip: border-box;
      /* -webkit-box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%);
      box-shadow: 0 15px 30px 0 rgb(0 0 0 / 5%); */
      border-radius: 0.5rem; 
      box-shadow:0 2px 5px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      overflow: hidden;
      /* border: 1px solid #6c757d!important; */
   }

   .shadow-sm-custom{
      --T68779821: 0 1px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
   }
 .card-title-custom{
    color: var(--info-dark);
    font-size: 0.975rem;
    line-height: 1.5;
    font-weight: 700;
 }

 .icon-holder-lg{
   width: 3rem;
   height: 3rem;
   border-radius: 3rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .icon-holder-sm{
   width: 2rem;
   height: 2rem;
   border-radius: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .icon-holder-md{
   padding-left: 0.8rem;
   padding-right: 0.8rem;
   height: 2.6rem;
   border-radius: 2.6rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .icon-holder-white{
   background-color: var(--white);
   color: var(--black) !important;
 }

 .icon-holder-info{
   background-color: var(--info-light);
   color: var(--info) !important;
 }

 .icon-holder-warning{
   background-color: var(--warning-light);
   color: var(--warning) !important;
 }

 .icon-holder-success{
   background-color: var(--success-light);
   color: var(--success-dark) !important;
 }

 .icon-holder-danger{
    background-color: var(--danger-light);
    color: var(--danger) !important;
 }

 .icon-holder-dark{
    background-color: var(--muted-light);
    color: var(--black) !important;
 }

 .icon-holder-ingigo{
    background-color: var(--indigo);
    color: var(--white) !important;
 }

 .block-custom{
    display: block;
 }

 .card-body-sm{
    padding: 0.6rem;
 }

 .cursor-pointer{
    cursor: pointer;
 }

 .card-header-custom{
      flex: 1 1 auto;
      padding: 1rem 1rem;
 }

 .firm-logo-sm{
   width: 10rem;
   height: 6rem;
   box-sizing: border-box;
   overflow: hidden;
   border-radius: 0.2rem;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .firm-logo-sm > img{
   width: auto;
   height:70%;
   object-fit: contain;
 }