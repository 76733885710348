#search-box {
    height: 100%;
    margin-right: 8px;
}

.search-setting {
    margin-right: 8px;
}

.search-btn {
    height: 100%;
}