.alert-custom {
    display: block;
    padding: 0.2em 1em;
    border-radius: 0.2rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.4rem;
}

.alert-danger-custom {
    background-color: var(--danger-light);
    border: 0.05rem solid var(--danger-dark);
}

.alert-success-custom {
    background-color: var(--success);
    border: 0.05rem solid var(--success-dark);
}

.alert-info-custom {
    background-color: var(--info-light);
    border: 0.05rem solid var(--info);
}

.alert-warning-custom {
    background-color: var(--warning-light);
    border: 0.05rem solid var(--warning);
}